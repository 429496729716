<template>
  <section>
    <section id="container">
      <div id="loading-animation">
        <i style="font-size: 2rem; color: white" class="el-icon-loading"></i>
      </div>
    </section>
  </section>
</template>
  
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
let camera, scene, renderer;
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      mesh: "",
      controls: "",
    };
  },
  mounted() {
    this.init();
    this.animate();
  },
  methods: {
    init() {
      const container = document.getElementById("container");
      camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        1,
        3000
      );
      renderer = new THREE.WebGLRenderer({ 
        antialias: true, //处理锯齿
        // logarithmicDepthBuffer: true, //处理重影 
      });

      camera.position.set(0, 0, 80);

      scene = new THREE.Scene();

      // renderer.setClearColor(new THREE.Color(0xf7f2f1));
      renderer.setSize(container.clientWidth, container.clientHeight);
      //   开启阻尼
      renderer.shadowMap.enabled = true;
      //   gltf颜色保真
      renderer.outputEncoding = THREE.sRGBEncoding;
      container.appendChild(renderer.domElement);
      // 创建背景纹理
      var textureLoader = new THREE.TextureLoader();
      var backgroundTexture = textureLoader.load(
        "../../assets/hall/banner.png"
      );
      // 设置场景背景
      scene.background = backgroundTexture;
      this.controls = new OrbitControls(camera, renderer.domElement);
      this.controls.target = new THREE.Vector3(0, 0, 0);
      window.addEventListener("resize", this.onWindowResize, false);
      renderer.domElement.addEventListener("click", this.onMouseClick, false);
      //   this.loadLight();

      this.initLight();
      this.load3D();
    },
    load3D() {
      // 获取用于显示加载动画的元素
      const loadingAnimation = document.getElementById("loading-animation");
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://threejs.org/examples/jsm/libs/draco/"
      );
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        "https://oss.zijingshuke.com/1699666905539.glb",
        (gltf) => {
          scene.add(gltf.scene);
          renderer.render(scene, camera);
          gltf.scene.position.set(-25, -45, -10);
          gltf.scene.scale.set(80, 80, 80);
          gltf.scene.rotation.set(0.1, 6.3, 0);
          this.mesh = gltf.scene;
          // 隐藏加载动画
          loadingAnimation.style.display = "none";
        },
        (xhr) => {
          // 模型加载过程中的处理逻辑
          if (xhr.lengthComputable) {
            const loadedPercentage = (xhr.loaded / xhr.total) * 100;
            console.log(loadedPercentage + "% loaded");
          }
        },
        (error) => {
          console.error(error);
          alert("加载出错");
        }
      );
    },
    loadLight() {
      // 环境光
      const ambient = new THREE.AmbientLight(0xffffff);
      scene.add(ambient);
      const pointLight = new THREE.PointLight(0xffffff, 0.5);
      pointLight.position.set(100, 200, 500);
      pointLight.color.setHSL(255, 255, 255);
      scene.add(pointLight);
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.updateProjectionMatrix();
    },
    initLight() {
      // 添加灯光, 四面八方的灯光
      //即从上方前方照射向场景中心。（ 前面）
      const light1 = new THREE.DirectionalLight(0xeeeeee, 0.9);
      light1.position.set(0, 10, 10);
      scene.add(light1);
      // 即从后方照射向场景中心。 后面
      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(0, 0, -10);
      scene.add(light2);
      //即从右侧照射向场景中心。 右面
      const light3 = new THREE.DirectionalLight(0xffffff, 1);
      light3.position.set(10, 0, 0);
      scene.add(light3);
      // 即从左侧照射向场景中心。左面
      const light4 = new THREE.DirectionalLight(0xffffff, 1);
      light4.position.set(-10, 0, 0);
      scene.add(light4);
      // 即从上方垂直向下照射。
      const light5 = new THREE.DirectionalLight(0xffffff, 1);
      light5.position.set(0, 10, 0);
      scene.add(light5);
      // 即从右上方前方照射向场景中心。
      const light6 = new THREE.DirectionalLight(0xffffff, 0.3);
      light6.position.set(5, 10, 0);
      scene.add(light6);
      // 即从上方右侧照射向场景中心。
      const light7 = new THREE.DirectionalLight(0xffffff, 0.3);
      light7.position.set(0, 10, 5);
      scene.add(light7);
      // 即从上方左侧照射向场景中心。
      const light8 = new THREE.DirectionalLight(0xffffff, 0.3);
      light8.position.set(0, 10, -5);
      scene.add(light8);
      // 即从左上方前方照射向场景中心
      const light9 = new THREE.DirectionalLight(0xffffff, 0.3);
      light9.position.set(-5, 10, 0);
      scene.add(light9);
      // 下方
      const light10 = new THREE.DirectionalLight(0xffffff, 1);
      light10.position.set(0, -10, 0);
      light10.target.position.set(0, 0, 0); // 设置照射方向指向场景中心
      scene.add(light10);
    },
    animate() {
      //  使模型上下跳动
      // setTimeout(()=>{
      //   console.log(this.mesh.position.y)
      //    const time = Date.now() * 0.002;
      // this.mesh.position.y = Math.cos(time) * 2 - 40;
      // },100)
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },
    getIntersects(event) {
      event.preventDefault(); // 阻止默认的点击事件执行,
      // 画一条线，来判断与物体是否交集，获取交集的模型，给模型绑定事件~~~~~~~~~~~~~~~~~~~~~~~~~~
      //声明 rayCaster 和 mouse 变量
      let rayCaster = new THREE.Raycaster();
      let mouse = new THREE.Vector2();

      //通过鼠标点击位置，计算出raycaster所需点的位置，以屏幕为中心点，范围-1到1
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1; //  这里为什么是-号，没有就无法点中

      //通过鼠标点击的位置(二维坐标)和当前相机的矩阵计算出射线位置
      rayCaster.setFromCamera(mouse, camera);

      //获取与射线相交的对象数组， 其中的元素按照距离排序，越近的越靠前。
      //+true，是对其后代进行查找，这个在这里必须加，因为模型是由很多部分组成的，后  代非常多。
      let intersects = rayCaster.intersectObjects(scene.children, true);

      //返回选中的对象
      return intersects;
    },
    onMouseClick(event) {
      let intersects = this.getIntersects(event);
      if (intersects.length !== 0 && intersects[0].object.type === "Mesh") {
        console.log(intersects[0].object.name);
      } else {
        console.log("请点击aim");
      }
    },
  },
};
</script>
  

  
  
  

<style scoped>
.page {
  position: relative;
}

.nav {
  position: absolute;
  top: 20px;
  right: 20px;
}

#container {
  width: 100%;
  height: 100vh;
  /* height: calc(100vh); */
  margin: 0;
  overflow: hidden;
  background: url("../../assets/hall/banner.png") center no-repeat;
  background-size: cover;
}
#loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

